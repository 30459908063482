.wrapper {

  .itemsWrapper {
    margin-bottom: 8px;
    & > div {
      margin-bottom: 12px;
    }
  }


  .customerNotesWrapper {
    //padding: 0 16px;
    .label {
      font-weight: bold;
      color: black;
    }
    .customerNotes {
      color: black;
    }
  }


  .infoLineWrapper {
    //padding: 0 16px;

    .infoLine {
      padding: 2px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
    }

    $borderColor: rgba(0,0,0,0.07);


    & > p:not(:first-child) {
      border-top: 1px solid $borderColor;
    }

  }

}
.wrapper {
  padding: 4px;
  background: rgba(255,255,255,0.3);
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .qty {
    color: white;
  }
}

input.swippyInput {
  outline: none;
  background-clip: padding-box;
  &:hover {
    border-color: #ff9900;
  }
  border-radius: 4px;

  min-height: 40px;

  &:focus {
    border-color: #ff9900;
  }
}

.header {
  height: 64px;
  //border-bottom: 1px solid rgba(0,0,0,0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  position: relative;
  line-height: 1;


  .menu {
    position: absolute;
    right: 8px;
    font-size: 22px;
  }

  .goBack {
    position: absolute;
    left: 8px;
    font-size: 22px;
  }
  .ristopubName {
    color: #ff9900;
    margin-bottom: 0px;
    margin-top: 0;
  }

}
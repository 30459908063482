.App {
  text-align: center;
}


* {
  /*font-family: 'Raleway', sans-serif;*/
  /*font-family: 'Open Sans', sans-serif;*/
  font-family: 'Montserrat', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.errorMessageAntd {

  .ant-message-notice-content {
    border-radius: 6px;
    background-color: #E92323;
    color: white;


    .ant-message-custom-content {
      display: flex;
      align-items: center;
    }
  }


  .anticon-close-circle {
    color: white;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*iOS Fix zoom input*/
input, select, textarea { font-size: 16px !important; }
body, html {
  overflow-y: scroll;
  font-size: 16px;
}


//.ant-btn-loading {
//  .ant-btn-loading-icon {
//    display: flex !important;
//  }
//}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background-color: #ff9900 !important;
}


.react-datepicker-popper {
  z-index: 10 !important;
}


.wrapper {
  display: flex;
  flex-direction: column;

  align-items: center;
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  .expl {
    text-align: center;
    opacity: 0.7;
    margin-bottom: 0;
    margin-top: 16px;
  }

  .subTitle {
    font-weight: normal;
    text-align: center;
    font-size: 16px;
    opacity: 0.7;
    margin: 0;
  }

  .qrCodeWrapper {
    $size: 80px;
    background-color: #ff9900;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    margin-top: 16px;
    .qrCodeIcon {
      font-size: 42px;
      color: white;
    }
  }
}


.wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 5;

  .closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    opacity: 0.5;
  }

  align-items: center;
  background-color: white;
  img {
    flex-shrink: 0;
    width: 100%;
  }

  &.opened {
    display: flex;

  }

}
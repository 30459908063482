.buttonElementsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 1em;
  width: 100%;
  &.disabled {
    * {
      color: rgba(0,0,0,0.4) !important;
    }
  }
}

.button {
  width: 100%;
  height: auto;
  min-height: 50px;
  //box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.52);
  padding: 4px 12px !important;
}
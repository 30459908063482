.swippyErrorOkBtn {
  border-color: #ff9900;
  background: #ff9900;

  &.ant-btn-primary:hover, &.ant-btn-primary:focus {
    $pressedColor: darken(#ff9900, 10%);
    border-color: $pressedColor;
    background: $pressedColor;
  }

}
.wrapper {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  //width: 100% !important;
  //max-width: none !important;
  padding: 16px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close {
      font-size: 22px;
      opacity: 0.5;
    }
  }


  .items {}

}
.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  .toInStructureInsertPhoneWrapper {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    padding: 8px;

  }
}

header.headerNoBorder {
  border-bottom-width: 0;
}
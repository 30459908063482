.wrapper {
  border: 1px solid #ff9900;
  padding: 4px;
  border-radius: 8px;
  //height: auto;
  display: flex;
  align-items: center;
  line-height: 1;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  height: 50px;
  .time {
    color: #ff9900;
  }


  &.selected {
    background-color: #ff9900;
    .time {
      color: white;
    }
  }
}
.wrapper {
  width: 100%;


  .cashPaymentBtnWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .or {
      font-size: 0.7em;
      opacity: 0.7;
      margin-top: 4px;
    }

    .cashPaymentBtn {
      padding: 0;
      height: auto;
    }
  }
}
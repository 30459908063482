.wrapper {
  cursor: pointer;
  border-bottom: 1px solid rgba(0,0,0,0.08);


  &.hover {
    background-color: #EAEAEA;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  .statusWrapper {
    display: flex;
    align-items: center;
  }
  .ristopubName {
    margin: 0;
    font-weight: bold;
  }


  .rightWrapper {
    display: flex;
    align-items: center;
    .badge {
      margin-right: 8px;
    }
  }


  .status {
    margin: 0;
  }


  .inProgress {
    color: #4b9e42;
    margin: 0 0 0 4px;
  }

  .rightChevron {
    font-size: 18px;
    color: #ff9900;
  }
}
:global {
  button.swippyButton {

    &.ant-btn-link {
      color: #ff9900;
      & > span {
        text-decoration: underline;
      }
      padding: 0;
      &:hover:not(:disabled) {
        color: darken(#ff9900, 20%);
      }
    }


    &.ant-btn.ant-btn-text {
      color: #ff9900;
      text-transform: uppercase;
    }

    &.ant-btn-primary {




      padding: 4px;
      &:not(:disabled), &:active:not(:disabled), &:focus:not(:disabled) {
        background-color: #ff9900;
        border-color: #ff9900;
      }

      border-radius: 24px;
      min-width: 120px;



      &:not(:disabled).hover {
        $pressedPrimary: darken(#ff9900, 12%);
        background-color: $pressedPrimary;
        border-color: $pressedPrimary;
      }
    }
  }
}


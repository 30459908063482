.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  .emptyList {
    margin: 0;
    //text-align: center;
    width: 100%;
    opacity: 0.7;
  }

  .selectedTime {
    padding: 0px 20px;
    text-align: center;
    opacity: 0.7;
    font-weight: bold;
  }


  .timeWrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: auto;
    //padding-bottom: 12px;
    height: 100%;
    & > div:not(:last-child) {
      margin-right: 4px;
    }
  }


}
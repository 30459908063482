.dropdownWrapper {
  background-color: white;
  padding: 6px;
  box-shadow: 0 0 5px 3px rgba(0,0,0,0.22);
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;

  .loadingWrapper {
    display: flex;
    padding-top: 2px;
    justify-content: center;
    align-items: center;
  }


  .noAddress {
    text-align: center;
    margin: 0;
    opacity: 0.7;
  }

  .suggestion {
    padding: 4px;
    &.suggestionActive {
      background-color: transparentize(#ff9900,0.3)
    }
  }
}

.missingInfoWrapper {
  padding: 4px;
}

.missiInfoTitle {
  margin: 0;
  color: red;
}
.missingInfoList {
  li {
    color: red;
  }
}

.searchInputWrapper {
  .searchInput {
    outline: none;
    width: 100%;
    border: 1px solid rgba(0,0,0,0.15);
    padding: 4px;
    border-radius: 4px;
    min-height: 40px;
  }
}

